require('./src/assets/css/global.css')
const React = require('react')
const Context = require('./src/components/Context').default

exports.onRouteUpdate = ({location, prevLocation}) => {
    // console.log('new pathname', location.pathname)
    // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
}
// exports.wrapPageElement = ({element, props}) => {
//     console.log({props})
//     return (
//         // <I18nextContext.Provider value={I18nextProvider}>
//         <Context>
//             <I18nextProvider i18n={i18next}>
//                 <Page {...props}>{element}</Page>
//                 {/* </I18nextProvider> */}
//             </I18nextProvider>
//         </Context>
//     )
// }

exports.wrapPageElement = ({element}) => {
    return <Context>{element}</Context>
}

// exports.wrapPageElement = ({props, element}) => {
//     // console.log({element, props})
//     return React.cloneElement(
//         element, // I18nextProvider
//         props,
//         element.props.children &&
//             React.cloneElement(
//                 element.props.children, // I18nextContext.Provider
//                 element.props.children?.props,
//                 React.createElement(
//                     Context,
//                     element.props.children?.props,
//                     React.createElement(
//                         Page,
//                         {...props, ...element.props.children?.props} //{page props, component props}
//                     )
//                 )
//             )
//     )
// }

import React, {createContext, useState} from 'react'

export const GlobalProvider = createContext({})
const ContextProviderComponent = ({children}) => {
    const [selectedColor, setSelectedColor] = useState(null)
    const [selectedCursor, setSelectedCursor] = useState(null)
    const [isUserSawPopup, setIsUserSawPopup] = useState(false)
    const [showContactPopop, setShowContactPopop] = useState(false)

    return (
        <GlobalProvider.Provider
            value={{
                selectedColor,
                selectedCursor,
                isUserSawPopup,
                showContactPopop,
                setShowContactPopop,
                setIsUserSawPopup,
                setSelectedColor,
                setSelectedCursor,
            }}>
            {children}
        </GlobalProvider.Provider>
    )
}

export default ContextProviderComponent
